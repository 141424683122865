import React, {useState} from 'react';
import s from './toggle.module.scss';
import { c } from '../../utils';
import {ComponentProps} from '../../types';

export type ToggleProps = {
  onChange?: (value: string | undefined) => any;
  options?: {
    label: string;
    value: string;
  }[];
} & ComponentProps;
const Toggle = ({ className, options, onChange, ...props }: ToggleProps) => {
  const [selected, setSelected] = useState<string>();

  const handleClick = (value: string) => {
    if (selected === value) {
      setSelected(undefined);
      onChange && onChange(undefined);
    }
    else {
      setSelected(value);
      onChange && onChange(value);
    }
  };

  return (
    <div
      className={c(s.toggle, className)}
      role='radiogroup'
      {...props}>
      {options?.map(({ label, value }) =>
        <button onClick={() => handleClick(value)} key={value}
          className={c(value === selected && s.selected)}
        >{label}</button>
      )}
    </div>
  );
};

export default Toggle;