/* eslint-disable react/no-unescaped-entities */
import React, {useEffect, useState} from 'react';
import '../index.scss';
import {Link} from 'gatsby';
import MadeWithLove from '../components/MadeWithLove/MadeWithLove';
import {useIsPastScroll} from '../hooks/useIsPastScroll';
import {c} from '../utils';
import Arrow from '../components/Arrow';
import { Helmet } from 'react-helmet';
import Fuse from 'fuse.js';
import FuseResult = Fuse.FuseResult;
import {Couple, People, Person} from './index';
import Toggle from '../components/Toggle';
import {Dict} from '../types';
import Modal from '../components/Modal';

interface Guest {
  name: string;
  count?: number;
  plus?: number;
  guest1?: string;
  guest2?: string;
  guest3?: string;
  guest4?: string;
  guest5?: string;
}
type GuestName = 'guest1' | 'guest2' | 'guest3' | 'guest4' | 'guest5';

const postData = (guest: Guest | undefined, guestData: Dict<GuestData>, message?: string) => {
  if (!guestData || !guest) {
    return undefined;
  }
  const rows = Object.keys(guestData)?.map(key => ({
    invite: guest?.name,
    message,
    key,
    ...guestData[key],
  }));

  return fetch('https://sheetdb.io/api/v1/gvafvdpiirngv?sheet=responses', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data:  rows }),
  }).then(res => res.json());
};

const useGuests = () => {
  const [guests, setGuests] = useState<Guest[]>();
  const [fuse, setFuse] = useState<Fuse<Guest>>();

  useEffect(() => {
    fetch('https://sheetdb.io/api/v1/gvafvdpiirngv')
      .then(res => res.json())
      .then(setGuests);
  }, []);

  useEffect(() => {
    if (guests) {
      setFuse(new Fuse(guests, {
        keys: ['name', 'guest1', 'guest2', 'guest3', 'guest4', 'guest5'],
        findAllMatches: false,
        isCaseSensitive: false,
        minMatchCharLength: 3,
        threshold: 0.3,
      }));
    }
  }, [guests]);

  return { guests, fuse };
};


const toList = (dict: Dict<any>) => {
  if (dict) {
    const vals = Object.values(dict);
    if (vals?.length > 0) {
      return vals;
    }
  }
};

const fullGuestList = (guest: Guest) => {
  return [1, 2, 3, 4, 5]
    .map(i => guest[`guest${i}` as GuestName])
    .filter(name => !!name);
};

const Asterisk = () => <span className='asterisk'>*</span>;

interface MealData {
  meal?: string;
  restrictions?: string;
}
const Meal = ({ onChange }: any) => {
  const [data, setData] = useState<MealData>({});
  useEffect(() => {
    onChange && onChange(data);
  }, [data]);

  return <>
    <h4>Please select a meal<Asterisk/></h4>
    <select defaultValue='default' onChange={event => setData({
      ...data,
      meal: event.currentTarget.value,
    })}>
      <option disabled value='default'>select an option ▾</option>
      <option value='beef'>Beef</option>
      <option value='fish'>Fish</option>
      <option value='vegetarian'>Vegetarian</option>
    </select>
    <h4>Please note any dietary restrictions</h4>
    <input onChange={ event => setData({
      ...data,
      restrictions: event.currentTarget.value,
    })}/>
  </>;
};


interface GuestData extends MealData {
  attending?: boolean;
  name?: string;
}

const Guest = ({ name, label, onChange }: any) => {
  const [data, setData] = useState<GuestData>({ name });
  useEffect(() => {
    onChange && onChange(data);
  }, [data]);

  useEffect(() => {
    setData({
      ...data,
      name,
    });
  }, [name]);

  const onSelectAttendance = (value: string | undefined) => {
    if (typeof value === 'undefined') {
      setData({});
    }
    else if (value == 'yes') {
      setData({
        ...data,
        attending: true,
      });
    }
    else {
      setData({ name, attending: false });
    }
    return undefined;
  };

  return <div className='guest'>
    <h2>Will <i>{label || name}</i> be attending?<Asterisk/></h2>
    <Toggle
      options={[
        { label: 'yes', value: 'yes'},
        { label: 'no', value: 'no'},
      ]}
      onChange={onSelectAttendance}
    />
    {data?.attending && <Meal onChange={(meal: MealData) => setData({
      ...data,
      ...meal,
    })}/>}
  </div>;
};

const PlusOne = ({ name, onChange }: any) => {
  const [data, setData] = useState<GuestData>();
  useEffect(() => {
    onChange && onChange(data);
  }, [data]);

  const onSelectAttendance = (value: string | undefined) => {
    if (typeof value === 'undefined') {
      setData({});
    }
    else if (value == 'yes') {
      setData({
        ...data,
        attending: true,
      });
    }
    else {
      setData({ attending: false });
    }
    return undefined;
  };

  return <div className='guest'>
    <h2>Will <i>{name}</i> be bringing a guest?<Asterisk/></h2>
    <Toggle
      options={[
        { label: 'yes', value: 'yes'},
        { label: 'no', value: 'no'},
      ]}
      onChange={onSelectAttendance}
    />
    {data?.attending && <>
      <h4>What is your guest's name?<Asterisk/></h4>
      <input onChange={ event => setData({
        ...data,
        name: event.currentTarget.value,
      })}/>
    </>}
    {data?.attending && <Meal onChange={(meal: MealData) => setData({
      ...data,
      ...meal,
    })}/>}
  </div>;
};


const validateGuest = (data: GuestData): boolean => {
  if (typeof data?.attending == 'undefined')
    return false;
  return !data?.attending || (!!data.meal && !!data.name);
};

const validateData = (data: Dict<GuestData>): boolean => !!toList(data)?.reduce((acc, d) => acc && validateGuest(d), true);


const Rsvp = () => {
  const scrolled = useIsPastScroll();
  const { fuse } = useGuests();
  const [searchText, setSearchText] = useState<string>();
  const [search, setSearch] = useState<FuseResult<Guest>[]>();
  const [selected, setSelected] = useState<Guest>();
  const [canSubmit, setCanSubmit] = useState<boolean>();
  const [showSubmit, setShowSubmit] = useState<boolean>();
  const [hasSubmitted, setHasSubmitted] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>();

  const [data, setData] = useState<Dict<GuestData>>({});

  const setGuestData = (key: string) => (d: GuestData) => setData({ ...data, [key]: d });

  useEffect(() => {
    setCanSubmit(validateData(data));
  }, [data]);


  useEffect(() => {
    if (searchText) {
      setSearch(fuse?.search(searchText));
    }
    else {
      setSearch(undefined);
    }
  }, [searchText]);

  const submitData = async () => {
    setLoading(true);
    try {
      const res = await postData(selected, data, message);
      if (res) {
        setHasSubmitted(true);
        setShowSubmit(false);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const listData = data && toList(data);
  const noAttendees = (listData?.filter(d => d?.attending).length || 0) === 0;
  return (
    <main className='rsvp'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RSVP | The Cantú's</title>
      </Helmet>

      <Link to={'/#more-info'} className={c('back-arrow', scrolled && 'shadow')}>
        <Arrow /> BACK
      </Link>

      <h1>RSVP</h1>

      {!hasSubmitted && !selected && <section>
        <h2 style={{ marginTop: 80 }}>Find Your Reservation</h2>
        <input
          className='search-box'
          onChange={event => setSearchText(event.currentTarget.value) }
        />
        <span className='hint'>Enter your first & last name as listed on the invite</span>
        {search
          ?.filter((_, i) => i < 3)
          ?.map(({ item: guest }, i) =>
            <button key={guest.name} className='internal-link result'
              style={{ '--row-number': i } as React.CSSProperties}
              onClick={() => setSelected(guest)}>
              {guest?.count == 1 ? <Person/> : (guest?.count == 2 ? <Couple /> : <People />)}
              <span>{guest.name}</span>
            </button>
          )}
      </section>}

      {!hasSubmitted && selected && <section>
        {(selected?.count || 0) > 1 && fullGuestList(selected)?.map((name, i) =>
          <>
            {i !== 0 && <hr/>}
            <Guest key={i} name={name} onChange={setGuestData(`guest${i+1}`)} />
          </>
        )}

        {selected?.count == 1 && <Guest label='you' name={selected?.guest1} onChange={setGuestData('guest1')} />}
        {selected?.plus && <>
          <hr/>
          <PlusOne label='you' onChange={setGuestData('plus1')} />
        </>}

      </section>}

      {hasSubmitted && <section>
        <h2>Thank you, <br/> your RSVP has been submitted</h2>
      </section>}

      {!hasSubmitted && canSubmit && <div className='submit-footer'>
        <button onClick={() => setShowSubmit(true)}>review</button>
      </div>}


      <MadeWithLove style={{ marginTop: '20vh'}}/>

      <Modal show={showSubmit} onClose={() => setShowSubmit(false) }>
        <h2 style={{ marginBottom: 40, marginTop: 0 }} className='uppercase'>Confirm Submission</h2>

        {listData
          ?.filter((guestData: GuestData) => guestData?.attending)
          ?.map((guestData: GuestData, i) => <div key={i} className='guest-review'>
            <h3>{guestData?.name}</h3>
            <div>{guestData?.meal}{guestData?.restrictions && ` - ${guestData.restrictions}`}</div>
          </div>)}

        {noAttendees && <div className='guest-review'>
          <h3>No attendees</h3>
        </div>}

        <hr style={{ margin: '0 auto', maxWidth: 40 }}/>
        <h4>Personal message (optional)</h4>
        <input onChange={event => setMessage( event.currentTarget.value)} />

        <button style={{ marginTop: 40, marginBottom: 0 }}
          disabled={loading}
          onClick={submitData}>submit</button>
      </Modal>
    </main>
  );
};
export default Rsvp;
